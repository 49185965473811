interface ArtImageProps {
    zoomLevel: number;
    img: string;
}
const ArtImage = ({zoomLevel, img} : ArtImageProps) => {
    const cn = `Art-image-img-${zoomLevel}`; 
    return (
        <div className="Art-image">
            <img src={img} alt="Fancy art" className={cn} />
        </div>
    );
};

export default ArtImage;