import "./App.css";
import ArtImage from "./ArtImage";
import { useEffect, useState } from "react";
import options from "./options.json";
import {
  Autocomplete,
  Button,
  TextField,
} from "@mui/material";
import axios from "axios";



const App = () => {
  const [hasWon, setHasWon] = useState(false);
  const [guess, setGuess] = useState("");
  const [zoomLevel, setZoomLevel] = useState<number>(10);
  const [text, setText] = useState(`Try to guess the painting in less than ${zoomLevel} attempts 🤔`);

  const [ID, setID] = useState("")
  
  useEffect(() => {
    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    if (!ID) {
        getTodaysId();
    }
  });

  const getTodaysId = async () => {
    const response = await axios.get(
      "/api/getTodaysArtle"
    );
    console.log(response.data.ID)
    setID(response.data.ID);
  };
  console.log(ID);
  const makeGuess = () => {
    if (guess === ID.toLowerCase()) {
      setText(`Yay! You won in ${10 - (zoomLevel-1)} attempts 🎉`);
      setZoomLevel(1);
      setHasWon(true);
    } else {
      setZoomLevel(zoomLevel - 1);
      setText(`Number of attempts: ${zoomLevel - 1}`);
    }
  };

  const help = () => {
    setZoomLevel(zoomLevel - 1);
    setText(`Number of attempts: ${zoomLevel - 1}`);
  };

  const selectGuess = (_: any, child: any) => {
    setGuess(child.value);
  };


  return (
    <div className="App">
      <ArtImage zoomLevel={zoomLevel} img={ID} />
      <div className="Guess">
        <div className="AutoComplete">
        <Autocomplete
          options={options}
          renderInput={(params) => <TextField {...params} label="Guess" />}
          onChange={selectGuess}
          disabled={zoomLevel === 0 || hasWon}
        />
        </div>
        <Button onClick={makeGuess} disabled={zoomLevel === 0 || hasWon}>
          Make Guess!
        </Button>
        <Button onClick={help} disabled={zoomLevel === 0 || hasWon}>
          Help!
        </Button>
      </div>
      {zoomLevel === 0 && <p>No attempts left. You lost 😔 The answer was {ID.slice(1).split("_large")[0].replaceAll("_", " ")}</p>}
      {zoomLevel > 0 && <p>{text}</p>}
    </div>
  );
};

export default App;
